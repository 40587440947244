import React from 'react';
import styled from 'styled-components';

function About() {
    return (
        <Wrapper>
            <Card>
                <Header>Ξενοδοχειακός εξοπλισμός</Header>
                <p>Η εταιρεία ΑΛΕΤΡΑΣ ΠΑΝΤΕΛΗΣ – OLKIS  μπορεί να σας προσφέρει λύσεις σχετικά με στρώματα ( latex, ελατηρίων, μονά, ημίδιπλα, διπλά, υπέρδιπλα ), ανωστρώματα  καθώς και ξενοδοχειακό ιματισμό.</p>
            </Card>
        </Wrapper>
    );
}

export default About;

const Wrapper = styled.div`
min-height: 400px;
width: 100%;
padding: 40px 0;
background-color: #A28489;
display: flex;
align-items: center;
justify-content: center;

`;
const Card = styled.div`
width: 90%;
background-color: #F5F3F2;
color: #2A2527;
border-radius: 15px;
font-size: 18px;
padding:30px;
max-width: 1200px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
>p{
    padding: 10px 0;
}
`;

const Header = styled.h1`
padding: 10px 0;



`;
