import React from 'react';
import styled from 'styled-components';

function Button({ children }) {
    return (
        <InfoButton>
            {children}
        </InfoButton>
    );
}

export default Button;

const InfoButton = styled.button`

  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid $red;
  border-radius: 0.6em;
  color: $red;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  margin: auto;
  padding: 1em 2.8em;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover,
  &:focus {
    color: black;
    outline: 0;
  }
  border-color: #A28489;
  color: white;
  box-shadow: 0 0 40px 40px #A28489 inset, 0 0 0 0 #A28489;
  transition: all 150ms ease-in-out;
  
  &:hover {
    box-shadow: 0 0 10px 0 #A28489 inset, 0 0 10px 4px #A28489;
  }

`;