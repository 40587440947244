import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Hero from "../components/Hero";
import Cards from "../components/Cards";
import About from "../components/About";
import Wholesales from '../components/Wholesales';
import { Helmet } from 'react-helmet';


const IndexPage = (props) => {
  const { data: { site: { siteMetadata } } } = props;
  console.log(siteMetadata);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{'Olkis | Ρολοκουρτίνες , Βενετικά στόρ ξύλινα & αλουμινίου , Συστήματα Εσωτερικής Σκίασης'}</title>
        <html lang={'el'} />
        <meta
          name="description"
          content={'Η επιχείρηση ΑΛΕΤΡΑΣ ΠΑΝΤΕΛΗΣ - OLKIS με έδρα τη Θεσσαλονίκη προσφέρει λύσεις για διάφορα συστήματα εσωτερικής σκίασης όπως ρολοκουρτίνες, βενετικά στόρ ξύλινα & αλουμινίου, κάθετες περσίδες, κουρτινόβεργες, αυτόματα συστήματα κουρτίνας ανέβα- κατέβα Επίσης ασχολείται με πτυσσόμενες πόρτες τύπου φυσούνα – φυσαρμόνικα, συστήματα σίτας και πολλά άλλα. Παρέχετε η υπηρεσία μέτρησης και τοποθέτησης των προϊόντων στους χώρους που επιθυμείται'}
        />
      </Helmet>
      <Layout>
        <>
          <Hero />
          <About backgroundColor />
          <Cards />
          <Wholesales />
        </>
      </Layout >
    </>
  );
};

export default IndexPage;

export const query = graphql`
{
site {
  siteMetadata {
    texts {
      paragraph
      title
    }
  }
}
}
`;