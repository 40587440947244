import React from 'react';
import styled from 'styled-components';
import Video from '../assets/videos/shade1.mp4';
import { TiTickOutline } from 'react-icons/ti';

function Hero() {
    return (
        <HeroContainer>
            <HeroBG>
                <VideoBG src={Video} type='video/mp4' autoPlay loop muted playsInline />
            </HeroBG>
            <HeroContentWrapper>
                <HeroContent>
                    <HeroItems>
                        <HeroH3>Οικιακός & ξενοδοχειακός εξοπλισμός</HeroH3>
                        <HeroList>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Ρολοκουρτίνες</ListItem>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Βενετικά στόρ ξύλινα & αλουμινίου</ListItem>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Κάθετες περσίδες</ListItem>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Κουρτινόβεργες</ListItem>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Αυτόματα συστήματα κουρτίνας ανέβα- κατέβα</ListItem>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Πτυσσόμενες πόρτες τύπου φυσούνα – φυσαρμόνικα</ListItem>
                            <ListItem><span style={{ padding: '0px 2px' }}><TiTickOutline /></span>  Συστήματα σίτας</ListItem>
                        </HeroList>
                    </HeroItems>
                </HeroContent>
            </HeroContentWrapper>
        </HeroContainer>
    );
}

export default Hero;

// Styles bellow

const HeroContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
color: white;
position: relative;
margin-top: -80px;
`;

const HeroBG = styled.div`
position: absolute;
top: 0;
left: 0;
z-index: -1;
width: 100%;
height: 100%;
overflow: hidden;
`;

const VideoBG = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
`;

const HeroContentWrapper = styled.div`
width: 100%;
max-width: 1100px;

`;

const HeroContent = styled.div`
    color: black;
    z-index: 2;
    max-width: 1200px;
    width: 100%;
    width: 650px;
    background-color: #171c22ad;
    padding: 35px 50px;
    border-radius: 20px;
@media screen and (max-width: 768px){
    display: block;
    display: flex;
    width: 80%;
    padding: 10px 5px;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    }
`;

const HeroItems = styled.div`
    color: white;
@media screen and (max-width: 768px){
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 5px;
}
`;

// const HeroH1 = styled.h1`
//     padding-bottom: 15px;
// @media screen and (max-width:768px){
//     display: none;
// }
// `;

const HeroH3 = styled.h3`
    padding: 10px 0px;
    font-size: 24px;
@media screen and (max-width:768px){
    text-align: center;
}
`;

const HeroList = styled.ul`
    list-style: none;
`;

const ListItem = styled.li`
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 3px 0px;
`;

