import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Button from './elements/Button';
import * as styles from './Cards.module.scss';
function Cards() {
    return (
        <CardsSection id="prods">
            <Top />
            <Bottom />
            <CardsWrapper>
                <Card>
                    <ImageWrapper>
                        <StaticImage quality={100} className={styles.staticImage} loading='lazy' src='../images/screen3.jpeg' alt="mia mikri epuikefalida" />
                    </ImageWrapper>
                    <Text>
                        <Epikefalida >Συστήματα Εσωτερικής Σκίασης</Epikefalida>
                        <List>
                            
                            <Listitem>Ρολοκουρτίνες</Listitem>
                            <Listitem>Βενετικά στορ</Listitem>
                            <Listitem>Κάθετες περσίδες</Listitem>
                            <Listitem>Πάνελ</Listitem>
                            <Listitem>Πλισέ</Listitem>
                            {/* Τα ποιοτικά συστήματα σκίασης κατασκευάζονται στη διάσταση που επιθυμούμε. */}
                        </List>
                        <AncorTag to='/products/rollerblinds'><Button>Περισσοτερα...</Button></AncorTag>
                    </Text>
                </Card>
                <Card>
                    <ImageWrapper>
                        <StaticImage quality={100} className={styles.staticImage} loading='lazy' src='../images/ptyssomeni-porta1.jpg' alt="mia mikri epuikefalida" />
                    </ImageWrapper>
                    <Text>
                        <Epikefalida>Πτυσσόμενες πόρτες</Epikefalida>
                        <List>
                            <Listitem>τύπου <em>φυσούνα - φυσαρμόνικα</em></Listitem>
                            {/* <Listitem>τύπου <em>φυσαρμόνικα</em></Listitem> */}
                            <Listitem>Κατασκευάζονται από φύλλα PVC μεγάλης αντοχής</Listitem>
                        </List>
                        <p>Kαταλαμβάνουν <em>περιορισμένο</em> χώρο και μπορούμε να τις χρησιμοποιήσουμε και για διαχωρισμό μεγάλων ενιαίων χώρων</p>
                        <AncorTag to='/products/ptyssomenes_portes'><Button>Περισσοτερα...</Button></AncorTag>
                    </Text>
                </Card>
                <Card>
                    <ImageWrapper>
                        <StaticImage quality={100} className={styles.staticImage} loading='lazy' src='../images/sita2.png' alt="Σίτα Κουρτίνα" />
                    </ImageWrapper>
                    <Text>
                        <Epikefalida>Σίτες</Epikefalida>
                        <p>Οι αντικουνουπικές  σίτες  κατασκευάζονται από ύφασμα άριστης ποιότητας και ανάλογα με τις ανάγκες μπορούν να διατεθούν με τους εξής μηχανισμούς. Ορίζοντιας κίνησης,  κάθετης κίνησης, ανοιγόμενες, συρόμενες / επάλληλες δίφυλλες, σταθερά τελάρα.</p>
                        <AncorTag to='/products/sites'><Button>Περισσοτερα...</Button></AncorTag>
                    </Text>
                </Card>
                <Card>
                    <ImageWrapper>
                        <StaticImage quality={100} className={styles.staticImage} loading='lazy' src='../images/kourtinoksylo3.jpg' alt="Κουρτινόξυλό" />
                    </ImageWrapper>
                    <Text>
                        <Epikefalida>Συστήματα κουρτινών</Epikefalida>
                        <List>
                            <Listitem><strong>Κουρτινόβεργες </strong>
                                Μεγάλη ποικιλία από  ξύλινες  και μεταλλικές  κουρτινόβεργες.</Listitem>
                            <Listitem><strong>Σιδηρόδρομοι </strong>Σιδηρόδρομοι με διάφορους μηχανισμούς, με μονό, διπλό η τριπλό οδηγό που  μπορούν να καλύψουν κάθε ανάγκη για το κρέμασμα των κουρτινών.</Listitem>
                        </List>
                        <AncorTag to='/products/kourtines'><Button>Περισσοτερα...</Button></AncorTag>
                    </Text>
                </Card>

            </CardsWrapper>
        </CardsSection>
    );
}

export default Cards;

const CardsSection = styled.div`
min-height: 100vh;
position: relative;
display: flex;
flex-direction: column;
align-items: space-between;
background-color: #F5F3F2;
`;

const Top = styled.div`
background-image: url('../images/tilt.svg');
height: 350px;
width: 100%;
position: absolute;
top: 0;
left: 0;
`;

const Bottom = styled.div`
background-image: url('../images/tilt.svg');
height: 350px;
width: 100%;
position: absolute;
bottom: 0;
left: 0;
transform: rotate(180deg);
`;

const CardsWrapper = styled.div`
flex-grow: 1;
display: flex;
justify-content: space-around;
z-index: 100;
flex-wrap: wrap;
padding: 60px 0;
max-width: 1300px;
margin: auto;
`;

const Card = styled.div`
margin-top: 150px;
height: 700px;
border-radius: 15px;
width: 35%;
min-width: 340px;
background-color: rgba(224, 224, 224, 0.95);
margin-left: 40px;
margin-right: 40px;
margin-top: 130px;
display: flex;
flex-direction: column;
align-items: center;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

@media screen and (max-width :768px){
    margin-left: 0px;
    margin-right: 0px;
    width: 90%;
    margin-top: 120px;
    min-width: 280px;
}
@media screen and (max-width :960px){
   
    min-width: 280px;
    margin-top: 120px;
}
`;

const ImageWrapper = styled.div`
border-radius: 15px;
overflow: hidden;
margin-top: -90px;
width: 90%;
min-height: 400px;
max-height: 400px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

@media screen and (max-width :768px){
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

/* width: %%; */
}
@media screen and (max-width :960px){
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    /* width: 85%; */
}
`;

const Text = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
flex-grow: 1;
width: 100%;
padding: 10px 40px;
color: #2A2527;
font-size: 18px;
@media screen and (max-width :768px){
font-size: 16px;
}
`;

const Epikefalida = styled.h2`
padding: 10px 0;
/* color: #A28489; */
color: black;
`;

const List = styled.ul`

`;

const AncorTag = styled(Link)`
text-decoration: none;
`;

const Listitem = styled.li`
padding: 2px;
`;