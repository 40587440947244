import React from 'react';
import styled from 'styled-components';

function About({ header, text, backgroundColor }) {
    return (
        <Wrapper backgroundColor={backgroundColor}>
            <Card>
                <Header>{header ?? 'ΑΛΕΤΡΑΣ ΠΑΝΤΕΛΗΣ -  OLKIS'}</Header>
                <div>
                {text ?? <>
                    <p>Η επιχείρηση  <em>ΑΛΕΤΡΑΣ ΠΑΝΤΕΛΗΣ -  OLKIS</em>  με έδρα τη Θεσσαλονίκη
                        προσφέρει  λύσεις για διάφορα συστήματα εσωτερικής σκίασης όπως  ρολοκουρτίνες, βενετικά στόρ ξύλινα & αλουμινίου, κάθετες περσίδες, κουρτινόβεργες, αυτόματα συστήματα κουρτίνας ανέβα- κατέβα.
                    </p>
                    <p>
                        Επίσης ασχολείται  με πτυσσόμενες πόρτες τύπου φυσούνα – φυσαρμόνικα, συστήματα σίτας  και πολλά άλλα.</p>
                    <p>
                        <strong> Παρέχετε η υπηρεσία μέτρησης και τοποθέτησης  των προϊόντων στους χώρους που επιθυμείται</strong> </p>
                </>}
                </div>
            </Card>
        </Wrapper>
    );
}

export default About;

const Wrapper = styled.div`
min-height: 300px;
width: 100%;
padding:  10px 0px;
background-color: ${(props) => props.backgroundColor ? '#A28489' : 'transparent'};
display: flex;
align-items: center;
justify-content: center;
`;

const Card = styled.div`
width: 90%;
background-color: #4D4D56;
color: white;
max-width: 1300px;
border-radius: 15px;
font-size: 16px;
padding: 30px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px - 2px, rgba(0, 0, 0, 0.3) 0px 3px 7px - 3px;
> p{
    padding: 10px 0;
    font-size: 18px;
}
`;

const Header = styled.h2`
padding: 10px 0;
font-size: 30px;
`;;
